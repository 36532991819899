import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import {
	OdysseySaleBenefits,
	Header,
	Layout,
	SalePanel,
	SaleStages,
	SpaaceIcon,
	TransactionModal,
} from "src/components";
import { DEPOSIT_OPTIONS } from "src/constants";
import { PostTransactionPanel } from "src/components/commons/panels/post-transaction-panel/PostTransactionPanel";
import { BP, getWindowSize } from "src/utils/responsivness";
import {useUsdtDeposit} from "../../services";


export const PrivateSaleView = () => {
	const [currentResponsiveFormat, setCurrentResponsiveFormat] =
		useState<number>(getWindowSize());

	useEffect(() => {
		const handleResize = () => {
			setCurrentResponsiveFormat(getWindowSize());
		};
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const [amount, setAmount] = useState(DEPOSIT_OPTIONS[0]);

	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		setLoaded(true);
	}, []);

	const {
		write: { isLoading: isWriting, write, reset },
		isSuccess,
		isLoading,
	} = useUsdtDeposit(amount);


	return (
		<Layout gradient>
			<Header />

			<TransactionModal isOpen={isWriting} />

			<div className={styles.container}>
				<div className={styles.logo}>
					<SpaaceIcon
						height={currentResponsiveFormat === BP.MOBILE ? "50" : undefined}
						width={currentResponsiveFormat === BP.MOBILE ? "300" : undefined}
					/>
				</div>

				{/* Main cards container */}
				<div className={styles.cardsContainer}>
					<div
						className={`${styles.card}  ${
							loaded
								? "translate-x-0 opacity-100"
								: "translate-x-[100%] opacity-0"
						}`}
					>
						<SaleStages stageStatus={0} />
					</div>
					<div
						className={`${styles.card} ${
							loaded ? "translate-y-0 opacity-100" : "translate-y-12 opacity-30"
						}`}
					>
						<div
							className={`${styles.mainPanel} ${
								(isLoading || isSuccess) && styles.thanksOpen
							}`}
						>
							{isLoading || isSuccess ? (
								<PostTransactionPanel
									isFinished={isSuccess}
									usdAmount={amount}
									reset={reset}
								/>
							) : (
								<SalePanel
									amount={amount}
									setAmount={setAmount}
									write={write}
									isLoading={isWriting || isLoading}
								/>
							)}
						</div>
					</div>
					<div
						className={`${styles.card} -z-10 ${
							loaded
								? "translate-x-0 opacity-100"
								: "translate-x-[-100%] opacity-0"
						}`}
					>
						<OdysseySaleBenefits />
					</div>
				</div>

			</div>
		</Layout>
	);
};
